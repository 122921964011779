.feedback-form {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .question-container {
    margin-bottom: 20px;
  }
  
  .star-rating {
    display: flex;
    align-items: center;
  }
  
  .text-response {
    margin-top: 10px;
  }
  
  .submit-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  