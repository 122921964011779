@import url('https://fonts.googleapis.com/css2?family=Playwrite+NL:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hubballi&display=swap');

.header {
  font-family: "Playwrite NL", cursive !important;
  font-optical-sizing: auto;
}

.text {
  font-family: "Hubballi", sans-serif !important;
  font-size: 20px !important;
  color: black;
}

/*----------------------------------------------LOGIN----------------------------------------------*/
.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsiveImage {
  width: 100%;
  height: auto;
}

@media (max-width: 600px) {
  .imageContainer {
    display: none;
  }
}

/*----------------------------------------------TABLE----------------------------------------------*/
.table-text {
  font-size: 16px;
  font-family: "Hubballi", sans-serif !important;
}

.table-text2 {
  font-size: 16px;
  font-weight: 500 !important;
  font-family: "Hubballi", sans-serif !important;
}

/*----------------------------------------------DRAWER----------------------------------------------*/
.logo-md {
  display: none;
  height: 50px !important;
  margin-right: 8px;
}

@media (min-width: 960px) {
  .logo-md {
    display: flex;
  }
}

.logo-xs {
  display: flex;
  height: 50px !important;
  margin-right: 8px;
}

@media (min-width: 960px) {
  .logo-xs {
    display: none;
  }
}

.logo-text-md {
  margin-right: 16px;
  display: none;
  font-family: monospace;
  font-weight: 700;
  letter-spacing: 0.3rem;
  color: inherit;
  text-decoration: none;
}

@media (min-width: 960px) {
  .logo-text-md {
    display: flex;
  }
}

.logo-text-xs {
  margin-right: 16px;
  display: flex;
  flex-grow: 1;
  font-family: monospace;
  font-weight: 700;
  letter-spacing: 0.3rem;
  color: inherit;
  text-decoration: none;
}

@media (min-width: 960px) {
  .logo-text-xs {
    display: none;
  }
}

.nav-links {
  flex-grow: 1;
  display: none;
}

@media (min-width: 960px) {
  .nav-links {
    display: flex;
  }
}

.menu-button-xs {
  flex-grow: 1;
  display: flex;
}

@media (min-width: 960px) {
  .menu-button-xs {
    display: none;
  }
}

/*----------------------------------------------CARD----------------------------------------------*/
.card-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  word-break: break-word;
}

.card-content .table-text2 {
  font-size: 20px;
  flex: 1 1 100%;
  margin-bottom: 10px;
}

@media (min-width: 600px) {
  .card-content .table-text2 {
    flex: 1;
    margin-bottom: 0;
  }
}